import request from '@/utils/request'

export const getFileSetup = () => {
  return request({
    method: 'GET',
    url: '/cms/export_jobs/setup'
  })
}

export const getFileList = (params) => {
  return request({
    method: 'GET',
    url: '/cms/export_jobs',
    params
  })
}
