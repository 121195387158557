// import i18n from '@/i18n/index'

export default {
  tableDataItem: [{
    prop: 'name',
    label: '',
    Object: 'value',
    width: '150'
  }, {
    prop: 'file_name',
    label: '',
    Object: 'value',
    width: '400'
  }, {
    prop: 'applied_filters',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'status',
    label: '',
    Object: 'value',
    width: '200'
  }, {
    prop: 'created_by',
    label: '',
    Object: 'value',
    width: '240'
  }, {
    prop: 'created_at',
    label: '',
    date: 'YYYY-mm-dd HH:MM',
    name: 'value',
    width: '240'
  }]
}
