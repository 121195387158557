<template>
  <div>
     <el-card>
      <search-form ref="searchForm" :tabData="tabData" labelWidth="120px" :searchFormData="searchFormData" @search="search" @tabChange="tabChange">
         <!-- <template #topBtn>
          <el-button class="marginRight20" type="primary" size="small">{{$t('button.salesReport')}}</el-button>
          <el-button class="marginRight20" type="primary" size="small">{{$t('button.freightList')}}</el-button>
          <el-button class="marginRight20" type="primary" size="small">{{$t('button.orderExport')}}</el-button>
        </template> -->
      </search-form>
      <create-table :operateVisible="true" ref="createTable" :loading="loading" :btnType="[]" :total_count="pageData.total_count ? pageData.total_count : 0" :current_page.sync="pageData.page" :papeSize.sync="pageData.per_page" :topButtonVisible="false" :tableData="tableData" :tableDataItem="tableDataItem" :pageVisible="true" :pageData="pageData" :checkBoxVisible="false">
        <template #rightBtn="scope">
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="marginBottom8" v-for="(item, index) in scope.row.member_actions.default_actions" :key="'default_actions' + index" >
            <el-button type="primary" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
          </el-col>
          <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24" class="marginBottom8" v-for="(item, index) in scope.row.member_actions.custom_actions" :key="'custom_actions' + index">
            <el-button type="primary" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
          </el-col>
        </template>
      </create-table>
    </el-card>
  </div>
</template>

<script>
import { getFileList, getFileSetup } from '@/services/download'
// import loading from '@/utils/loading.json'
import searchForm from '@/components/searchForm'
import jsonData from './jsonData.js'
import createTable from '@/components/createTable'
import { mixin } from '@/mixins/mixin'
export default {
  name: 'managerUserOrders',
  mixins: [mixin],
  components: {
    createTable,
    searchForm
  },
  data () {
    return {
      tableDataItem: jsonData.tableDataItem
    }
  },
  watch: {
    $route (to, from) {
      // this.$nextTick(() => {
      //   this.search()
      //   this.$refs.createTable.$refs.table.doLayout()
      // })
    }
  },
  created () {
    // this.search()
    this.queryData(getFileSetup)
  },
  methods: {
    handleClick () {},
    // 查询
    search (data) {
      if (data) this.searchData = data
      const { page, per_page } = this.pageData
      Object.assign(this.searchData, { page, per_page })
      this.loading = true
      getFileList(this.searchData).then(res => {
        if (res.data) {
          this.tableData = res.data.objects
          this.tabData = res.data.scopes
          Object.assign(this.pageData, res.data.pagination)
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>

<style>

</style>
